import React from "react";
import { Header, Footer } from "../../layout";
import { BuySectionTop, DetialsSection } from "../../modules";

export const Buy = () => {
  return (
    <div>
      <Header />
      <div className="">
        <BuySectionTop />
        <DetialsSection />
      </div>
      <Footer />
    </div>
  );
};
