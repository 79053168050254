import React from "react";
import "./scss/index.scss";

export const IntroSection = () => {
  return (
    <div className="intro_section">
      <div className="nested_introSection">
        <div className="heading_section">
          <p>YOUR PORTAL TO SPORTS NFTS</p>
          <h2>ATHLETE REGISTRATION</h2>
        </div>
      </div>
    </div>
  );
};
