import React from "react";
import "./scss/index.scss";
import IconCricle from "../../../assets/images/Icon-check-circle.png";
export const NFTMarketDesc = () => {
  return (
    <div className="nftmarkplace_desc">
      <div className="nested_nftmaketdesc">
        <h1>NFT Platform for Young Stars</h1>
        <div className="circle_section flex justify-center items-center">
           <div className="section_one flex flex-col justify-center items-center text-white gap-4"> 
          <div className="img_section">
            <img src={IconCricle} alt="IconCricle" className="w-14" />
          </div>
          <div className="content_section">
            <h2>Create a player profile.</h2>
          </div>
          </div>
          {/* section two */}
          <div className="section_one flex flex-col justify-center items-center text-white gap-4"> 
          <div className="img_section">
            <img src={IconCricle} alt="IconCricle" className="w-14" />
          </div>
          <div className="content_section">
            <h2>Apply for NFT creation.</h2>
          </div>
          </div>
          {/* section three */}
          <div className="section_one flex flex-col justify-center items-center text-white gap-4"> 
          <div className="img_section">
            <img src={IconCricle} alt="IconCricle" className="w-14" />
          </div>
          <div className="content_section">
            <h2>Trade your NFT moments.</h2>
          </div>
          </div>
          {/* section Four */}
          <div className="section_one flex flex-col justify-center items-center text-white gap-4"> 
          <div className="img_section">
            <img src={IconCricle} alt="IconCricle" className="w-14" />
          </div>
          <div className="content_section">
            <h2>Sell on the open market.</h2>
          </div>
          </div>

        </div>
      </div>
    </div>
  );
};
