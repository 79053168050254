import React from "react";
import { Header, Footer } from "../../layout";
import { IntroSection, CompanyName } from "../../modules";
import {ContactForm} from "../../modules"


export const Registration = () => {
  return (
    <div>
      <Header />
      <div>
        <IntroSection />
        <CompanyName/>
        <ContactForm/>

      </div>
      <Footer/>
    </div>
  );
};
