import React from "react";
import { Header, Footer } from "../../layout";
import {
  TopSection,
  SecondSectionAbout,
  OurJounary,
  ChooseUs,
  BrowsieMarketPlace,
} from "../../modules";

export const About = () => {
  return (
    <div>
      <Header />
      <div>
        <TopSection />
        <SecondSectionAbout />
        <OurJounary />
        {/* <ChooseUs /> */}
        <BrowsieMarketPlace />
      </div>
      <Footer />
    </div>
  );
};
