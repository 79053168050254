import React from 'react'
import "./scss/index.scss";
import { BsTwitter, BsInstagram, BsLinkedin } from "react-icons/bs";
import {Btn} from "../../../components"
import { BsDiscord } from "react-icons/bs";
import { BiDownArrow } from "react-icons/bi";
export const ContactForm = () => {
  return (
    <div className='contactForm_Section'>
       <div className='nested_contactForm'>
         <div className='contact_formSection'>
                   <p className='react_out'>REACH OUT</p>
                <h1 className='my-5'>CONTACT US</h1>
                <p className='desc my-5'>Feel free to use the form and contact us about registering your NFT.</p>
                <div className="social_icon my-5 flex justify-start items-start gap-5">
          <BsInstagram className="text-white text-2xl" />
          <BsTwitter className="text-white text-2xl" />
          <BsDiscord className="text-white text-2xl" />
          <BsLinkedin className="text-white text-2xl" />
        </div>
        <div className='address_contactSecttion'>
          <div>Email: <a href='#/'>thegoldstandardgrp@gmail.com</a> </div>
          <div>Tel: <a href='#/'> +1 404-954-2887</a> </div>
          <div>Location: <span>30 N Gould St. Sheridian WY</span> </div>
        </div>

         </div>
         <div className='from_section'>
            <h2>Send Us A Message</h2>
           <form>
               <div>
               <input type="text" placeholder='First Name' />
               </div>
               <div>
               <input type="text" placeholder='Last Name' />
               </div>
               <div>
               <input type="email" placeholder='Email' />
               </div>
               <div>
                 <textarea cols="30" rows="3" placeholder='Message'></textarea>
               </div>
               <div className='formSection_btn'>
               <Btn value={"Submit"}/>
               </div>
            </form> 
         </div>
       </div>
    </div>
  )
}
