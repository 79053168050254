import React from 'react';
import "./scss/index.scss";

export const CompanyName = () => {
    return (
        <div className='comapnyList'>
            <div className='nested_companyList'>
                <div className='companyName_heading'>
                    <h1 className='py-4'>How Company Name Works</h1>
                    <p className='py-4'>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes.</p>
                </div>
                <div className='content_section py-14'>
                  <div className='section_one'>
                    <h1>01</h1>
                    <h5>Lorem ipsum dolor sit amet</h5>
                    <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo</p>
                    </div>   
                    <div className='section_one'>
                    <h1>02</h1>
                    <h5>Lorem ipsum dolor sit amet</h5>
                    <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo</p>
                    </div>
                    <div className='section_one'>
                    <h1>03</h1>
                    <h5>Lorem ipsum dolor sit amet</h5>
                    <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo</p>
                    </div>
                    <div className='section_one'>
                    <h1>04</h1>
                    <h5>Lorem ipsum dolor sit amet</h5>
                    <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
