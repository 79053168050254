import React from "react";
import { Header, Footer } from "../index";
import { IntroSectionMarketPlace, NFTCollection } from "../../modules";

export const Marketplace = () => {
  return (
    <div>
      <Header />
      <div>
        <IntroSectionMarketPlace />
        <NFTCollection />
      </div>
      <Footer />
    </div>
  );
};
