import React from "react";
import { Home } from "./home";
import { Buy } from "./buy";
import { About } from "./about";
import { ContactUs } from "./contactus";
import { FaqsUs } from "./faqsus";
import { Registration } from "./registration";
import { Marketplace } from "./marketplace";
import { Routes, Route } from "react-router-dom";

export const MainRouting = () => {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/buy" element={<Buy />} />
        <Route path="/about-us" element={<About />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/registration" element={<Registration />} />
        <Route  path="/faq-us" element={<FaqsUs />} />
        <Route path="/marketplace" element={<Marketplace/>} />
      </Routes>
    </div>
  );
};
