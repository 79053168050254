import React from "react";
import "./scss/index.scss";
import imgone from "../../../assets/images/aboutimages/pc-edge-image.png";
import imgtwo from "../../../assets/images/aboutimages/tag-edge-image.png";
import imgfour from "../../../assets/images/aboutimages/heart-edge-image.png";
import imgthree from "../../../assets/images/aboutimages/people-edge-image.png";

export const OurJounary = () => {
  return (
    <div className="ourJounary_section">
      <div className="nested_ourJounary">
        <div className="heading_section">
          <h1>OUR JOURNEY</h1>
        </div>
        <div className="jounary_contentsection">
          <div className="section_one">
            <div className="content_section">
              <h2>CREATION OF THE 80/20 ECOSYSTEM</h2>
              <p>
              The 80/20 ecosystem revolutionizes traditional value distribution, allocating 80% of the generated value directly back to the community while reserving 20% for continued innovation and growth. 
              </p>
              <p>
              This model ensures sustainable development and greater rewards for active participants, fostering a thriving and engaged community.
              </p>
            </div>
            <div className="img_content flex  items-center">
              <img src={imgone} alt="imgone" />
              <h2 className="text-center">2021</h2>
            </div>
          </div>
          {/* section two */}
          <div className="section_one flex-row-reverse">
            <div className="content_section -mr-5">
              <h2>NFT LAUNCHPAD ROLLED OUT</h2>
              <p>
                
                Our 80/20 ecosystem is complemented by an NFT Launchpad, which serves as a pivotal platform for distributing tokens efficiently.
              </p>
              <p>
              This integration allows creators and participants to directly launch and trade digital assets, ensuring that the benefits of the ecosystem are maximized and accessible right from the start.
              </p>
            </div>
            <div className="img_content flex flex-row-reverse  items-center">
              <img src={imgtwo} alt="imgtwo" />
              <h2 className="text-center">2022</h2>
            </div>
          </div>
          <div className="section_one">
            <div className="content_section">
              <h2>EXPANSION AND GROWTH PERIOD</h2>
              <p>
              The year 2023 marked a significant growth period for us, focusing intensely on app development and comprehensive testing. This crucial phase was dedicated to refining our technology, enhancing the user experience, and strengthening security measures. 
              </p>
              <p>
              Our commitment during this time was to deliver a platform that exceeds user and stakeholder expectations, setting a robust foundation for scalable success in the future.
              </p>
            </div>
            <div className="img_content flex  items-center">
              <img src={imgthree} alt="imgthree" />
              <h2 className="text-center">2023</h2>
            </div>
          </div>
          <div className="section_one flex-row-reverse">
            <div className="content_section -mr-5">
              <h2>APP DEPLOYMENT</h2>
              <p>
              In 2024, we've transitioned into our deployment phase, rolling out innovative applications such as Bogle, Young Block Stars, and Blockchain Radio Network. 
              </p>
              <p>
              This stage is focused on launching these platforms to the public, leveraging the solid groundwork laid in the previous year. Our aim is to make a significant impact in the market, providing users with cutting-edge blockchain solutions that enhance their engagement and interaction within our ecosystem.
              </p>
            </div>
            <div className="img_content flex  items-center flex-row-reverse">
              <img src={imgfour} alt="imgfour" />
              <h2 className="text-center">2024</h2>
            </div>
          </div>
          
        </div>
      </div>
    </div>
  );
};
