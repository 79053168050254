import React from "react";
import "./scss/index.scss";
import { Btn } from "../../../components";

export const BrowsieMarketPlace = () => {
  return (
    <div className="browise_marketplace">
      <div className="nested_browisemarketplace">
        <h2>
          Showcasing our <br /> future athletic AND<br /> academic stars.
        </h2>
        <div className="browisemarketplace_btn">
        <Btn value={"Browse Young Stars"} />
        </div>
      </div>
    </div>
  );
};
