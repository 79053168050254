import React from 'react';
import "./scss/index.scss";
import sportslogo from "../../assets/images/logo.png"
import Icon_awesome_discord from "../../assets/images/Icon-awesome-discord.png";
import Icon_awesome_linkedin from "../../assets/images/Icon-awesome-linkedin.png";
import Icon_awesome_twitter from "../../assets/images/Icon-awesome-twitter.png";
import Icon_awesome_instagram from "../../assets/images/Icon-feather-instagram.png";

export const Footer = () => {
  return (
    <div className='footer_section py-14'>
      <div className="nested_footer">
       <div className="footer_one">
        <img src={sportslogo} alt="sportslogo" />
       </div>
       <div className="footer_two">
        <p className='uppercase font-bold tracking-wider'>Site Links</p> 
        <ul>
            <li> <a href="https://gsgnft.com">MarketPlace</a></li>
            <li><a href="https://gsgnft.com">Faq</a></li>
            <li><a href="https://gsgnft.com">Athlete</a></li>
            <li><a href="https://gsgnft.com">Registration</a></li>
            <li><a href="https://gsgnft.com">Contact Us</a></li>
        </ul>
       </div>
       <div className="footer_two">
        <p className='uppercase font-bold tracking-wider'>Partner Sites</p>
        <ul>
            <li> <a href="https://bogle.app.com" target='new'>Bogle</a></li>
            <li><a href="https://the8020.org" target='new'>The 80/20</a></li>
            <li><a href="https://goldstandardgrp.com" target='new'>GSG Corporate</a></li>
            <li><a href="https://goldbackers.info">Goldbackers</a></li>
            <li><a href="https://dcgelitenft.com">DCG Elite</a></li>
        </ul>
       </div>
       <div className="footer_two flex justify-start items-start gap-4">
        <img src={Icon_awesome_discord} alt="Icon-awesome-discord" className='w-8 cursor-pointer' />  
        <img src={Icon_awesome_linkedin} alt="Icon-awesome-Icon_awesome_linkedin" className='w-8 cursor-pointer' />  
        <img src={Icon_awesome_twitter} alt="Icon_awesome_twitter" className='w-8 cursor-pointer' />  
        <img src={Icon_awesome_instagram} alt="Icon_awesome_instagram" className='w-8 cursor-pointer' />          
       </div>
      </div>
    </div>
  )
}
