import React from "react";
import "./scss/index.scss";
import Slider from "react-slick";
import nft_img_a from "../../../assets/images/carsoal/nft-img-clifffuller.png";
import nft_img_b from "../../../assets/images/carsoal/nft-img-b.png";
import nft_img_c from "../../../assets/images/carsoal/nft-img-c.png";
import nft_img_d from "../../../assets/images/carsoal/nft-img-d.png";

var settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  rows: 2,
  //   slidesPerRow: 2,
  // initialSlide: 0,
  appendDots: (dots) => (
    <div
      style={
        {
          // backgroundColor: "#ddd",
          // borderRadius: "10px",
          // padding: "10px"
        }
      }
    >
      <ul style={{ margin: "0px" }}> {dots} </ul>
    </div>
  ),
  customPaging: (i) => (
    <div
      style={{
        width: "30px",
        // border: "1px blue solid",
        color: "#afe334",
      }}
    >
      {i + 1}
    </div>
  ),
  //   autoplaySpeed: 2000,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

export const NFTCollection = () => {
  return (
    <div className="nftCollection_Section">
      <div className="button_section"></div>
      <div className="carosal_section py-6">
        <Slider {...settings}>
          <div className="nested_card">
            {/* <h3>1</h3> */}
            <div className="title">
              <h2>Genesis drop</h2>
            </div>
            <div className="img">
              <img src={nft_img_a} alt="nft_img_a" />
            </div>
            <div className="role">
              <h5>
                Cliff <span>Fuller</span>{" "}
              </h5>
              <p className="uni_name">Virginia Union University</p>
              <div className="tag flex gap-2 items-center">
                <p>Basketball</p>
                <p>Academics</p>
              </div>
            </div>
          </div>
          <div className="nested_card">
            {/* <h3>2</h3> */}
            <div className="title">
              <h2>Genesis drop</h2>
            </div>
            <div className="img">
              <img src={nft_img_b} alt="nft_img_b" />
            </div>
            <div className="role">
              <h5>
                Malachi <span>Wildman</span>{" "}
              </h5>
              <p className="uni_name">Jackson state university</p>
              <div className="tag flex gap-2 items-center">
                <p>Football</p>
                <p>basketball</p>
              </div>
            </div>
          </div>
          <div className="nested_card">
            {/* <h3>3</h3> */}
            <div className="title">
              <h2>Genesis drop</h2>
            </div>
            <div className="img">
              <img src={nft_img_c} alt="nft_img_c" />
            </div>
            <div className="role">
              <h5>
                Malachi <span>Wildman</span>
              </h5>
              <p className="uni_name">Jackson state university</p>
              <div className="tag flex gap-2 items-center">
                <p>Football</p>
                <p>basketball</p>
              </div>
            </div>
          </div>
          <div className="nested_card">
            {/* <h3>4</h3> */}
            <div className="title">
              <h2>Genesis drop</h2>
            </div>
            <div className="img">
              <img src={nft_img_d} alt="nft_img_d" />
            </div>
            <div className="role">
              <h5>
                Malachi <span>Wildman</span>
              </h5>
              <p className="uni_name">Jackson state university</p>
              <div className="tag flex gap-2 items-center">
                <p>Football</p>
                <p>basketball</p>
              </div>
            </div>
          </div>
          <div className="nested_card">
            {/* <h3>5</h3> */}
            <div className="title">
              <h2>Genesis drop</h2>
            </div>
            <div className="img">
              <img src={nft_img_a} alt="nft_img_a" />
            </div>
            <div className="role">
              <h5>
                Malachi <span>Wildman</span>
              </h5>
              <p className="uni_name">Jackson state university</p>
              <div className="tag flex gap-2 items-center">
                <p>Football</p>
                <p>basketball</p>
              </div>
            </div>
          </div>
          <div className="nested_card">
            {/* <h3>6</h3> */}
            <div className="title">
              {" "}
              <h2>Genesis drop</h2>
            </div>
            <div className="img">
              <img src={nft_img_b} alt="nft_img_b" />
            </div>
            <div className="role">
              <h5>
                Malachi <span>Wildman</span>
              </h5>
              <p className="uni_name">Jackson state university</p>
              <div className="tag flex gap-2 items-center">
                <p>Football</p>
                <p>basketball</p>
              </div>
            </div>
          </div>
          <div className="nested_card">
            {/* <h3>7</h3> */}
            <div className="title">
              <h2>Genesis drop</h2>
            </div>
            <div className="img">
              <img src={nft_img_c} alt="nft_img_c" />
            </div>
            <div className="role">
              <h5>
                Malachi <span>Wildman</span>
              </h5>
              <p className="uni_name">Jackson state university</p>
              <div className="tag flex gap-2 items-center">
                <p>Football</p>
                <p>basketball</p>
              </div>
            </div>
          </div>
          <div className="nested_card">
            {/* <h3>8</h3> */}
            <div className="title">
              <h2>Genesis drop</h2>
            </div>
            <div className="img">
              <img src={nft_img_d} alt="nft_img_d" />
            </div>
            <div className="role">
              <h5>
                Malachi <span>Wildman</span>{" "}
              </h5>
              <p className="uni_name">Jackson state university</p>
              <div className="tag flex gap-2 items-center">
                <p>Football</p>
                <p>basketball</p>
              </div>
            </div>
          </div>
          <div className="nested_card">
            {/* <h3>8</h3> */}
            <div className="title">
              <h2>Genesis drop</h2>
            </div>
            <div className="img">
              <img src={nft_img_d} alt="nft_img_d" />
            </div>
            <div className="role">
              <h5>
                Malachi <span>Wildman</span>{" "}
              </h5>
              <p className="uni_name">Jackson state university</p>
              <div className="tag flex gap-2 items-center">
                <p>Football</p>
                <p>basketball</p>
              </div>
            </div>
          </div>
          <div className="nested_card">
            {/* <h3>8</h3> */}
            <div className="title">
              <h2>Genesis drop</h2>
            </div>
            <div className="img">
              <img src={nft_img_d} alt="nft_img_d" />
            </div>
            <div className="role">
              <h5>
                Malachi <span>Wildman</span>{" "}
              </h5>
              <p className="uni_name">Jackson state university</p>
              <div className="tag flex gap-2 items-center">
                <p>Football</p>
                <p>basketball</p>
              </div>
            </div>
          </div>
          <div className="nested_card">
            {/* <h3>8</h3> */}
            <div className="title">
              <h2>Genesis drop</h2>
            </div>
            <div className="img">
              <img src={nft_img_d} alt="nft_img_d" />
            </div>
            <div className="role">
              <h5>
                Malachi <span>Wildman</span>{" "}
              </h5>
              <p className="uni_name">Jackson state university</p>
              <div className="tag flex gap-2 items-center">
                <p>Football</p>
                <p>basketball</p>
              </div>
            </div>
          </div>
          <div className="nested_card">
            {/* <h3>8</h3> */}
            <div className="title">
              <h2>Genesis drop</h2>
            </div>
            <div className="img">
              <img src={nft_img_d} alt="nft_img_d" />
            </div>
            <div className="role">
              <h5>
                Malachi <span>Wildman</span>{" "}
              </h5>
              <p className="uni_name">Jackson state university</p>
              <div className="tag flex gap-2 items-center">
                <p>Football</p>
                <p>basketball</p>
              </div>
            </div>
          </div>{" "}
          <div className="nested_card">
            {/* <h3>8</h3> */}
            <div className="title">
              <h2>Genesis drop</h2>
            </div>
            <div className="img">
              <img src={nft_img_d} alt="nft_img_d" />
            </div>
            <div className="role">
              <h5>
                Malachi <span>Wildman</span>{" "}
              </h5>
              <p className="uni_name">Jackson state university</p>
              <div className="tag flex gap-2 items-center">
                <p>Football</p>
                <p>basketball</p>
              </div>
            </div>
          </div>
          <div className="nested_card">
            {/* <h3>8</h3> */}
            <div className="title">
              <h2>Genesis drop</h2>
            </div>
            <div className="img">
              <img src={nft_img_d} alt="nft_img_d" />
            </div>
            <div className="role">
              <h5>
                Malachi <span>Wildman</span>{" "}
              </h5>
              <p className="uni_name">Jackson state university</p>
              <div className="tag flex gap-2 items-center">
                <p>Football</p>
                <p>basketball</p>
              </div>
            </div>
          </div>
        </Slider>
      </div>
    </div>
  );
};
