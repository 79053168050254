import React from 'react';
import { Header, Footer } from "../index";
import {ContactForm} from "../../modules"

export const ContactUs = () => {
  return (
    <div>
        <Header/>
           <div>
         <ContactForm/>
           </div>
        <Footer/>
    </div>
  )
}
