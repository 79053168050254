import React from "react";
import "./scss/index.scss";
import imgPlayer from "../../assets/images/nft-img-buy.png";
import { Btn } from "../../components";
// import manImg from "../../";

export const BuySectionTop = () => {
  return (
    <div className="buy_sectiontop w-full bg-black">
      <div className="nested_section_top flex justify-between items-center gap-4">
        <div className="img">
          <img src={imgPlayer} alt="imgPlayer" />
        </div>
        <div className="content_buy">
          {/* <h1>Hello Word</h1> */}
          <h2>CLIFF FULLER JR. SENIOR YEAR COLLECTION - #/500</h2>
          <h1 className="py-4">RICHMOND CLASSIC</h1>
          <div className="small_section flex items-center justify-between">
            <p className="price">$75.00</p>
            <p className="rating">200 REMAINING</p>
          </div>
          <div className="buy_btn">
            <Btn value={"Login to Buy"} />
          </div>
          <p className="para">
            Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean
            commodo ligula eget dolor. Aenean massa. Cum sociis natoque
            penatibus et magnis dis parturient montes, nascetur ridiculus mus.
            Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem.
            Nulla consequat massa quis enim. Donec pede justo, fringilla vel,
            aliquet nec, vulputate eget, arcu. In enim justo, rhoncus ut,
            imperdiet a, venenatis vitae, justo. Nullam dictum felis eu pede
            mollis pretium. Integer tincidunt. Cras dapibus. Vivamus elementum
            semper nisi. Aenean vulputate eleifend tellus.
          </p>
          <div className="flex gap-3 text-white collection_id">
            <p className="">Collection id: <span>#0x7513f90fc527b77418e58e49c96df7cd1ceed599</span></p>
          </div>
          <div className="flex gap-3 text-white collection_id">
            <p className="">Edition of: <span>123</span></p>
          </div>
        </div>
      </div>
    </div>
  );
};
