import React from 'react';
import "./scss/index.scss";
// import marketplace_banner from "../../../assets/images/marketplace/marketplace-banner.png"
export const IntroSectionMarketPlace = () => {
  return (
    <div className='marketplace_topSection'>
     <div className="nested_topSection_marketplace">
          <p>YOUR PORTAL TO SPORTS NFTS</p>
          <h1>MARKETPLACE</h1>
          
     </div>
    </div>
  )
}
