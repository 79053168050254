import React, { useState } from "react";
import "./scss/index.scss";
import mainLogo from "../../assets/images/logo.png";
import { Sidebar } from "../index";
import { GiHamburgerMenu } from "react-icons/gi";
import { NavLink } from "react-router-dom";

export const Header = () => {
  const [isOpen, setIsopen] = useState(false);

  const ToggleSidebar = () => {
    isOpen === true ? setIsopen(false) : setIsopen(true);
  };
  return (
    <>
      <div className="header_section w-full">
        <div className="nested_header flex items-center justify-between">
        <div className="logo">
            <NavLink to={"/"}>
            <img src={mainLogo} alt="mainLogo" className="" />
            </NavLink>
          </div>
          <div className="links">
            <ul className="flex gap-4 items-center">
              <li>
                <NavLink to={"/"}>Home</NavLink>
              </li>
              <li>
                <NavLink to="/buy">Buy</NavLink>
              </li>
              <li>
                <NavLink to="/about-us">About</NavLink>
              </li>
              <li>
                <NavLink to="/marketplace">MarketPlace</NavLink>
              </li>
              <li>
                <NavLink to="/registration">Register</NavLink>
              </li>
              <li>
                <NavLink to="/contact-us">Contact Us</NavLink>
              </li>
              <li>
                <NavLink to="/faq-us">Faq</NavLink>
              </li>
            </ul>
          </div>
          <div
            className="btn-primary showButton p-4 text-base rounded-md cursor-pointer"
            onClick={ToggleSidebar}
          >
            {/* <i className="fa fa-bars"></i> */}
            <GiHamburgerMenu />
          </div>
        </div>
      </div>
      <div>
        <Sidebar ToggleSidebar={ToggleSidebar} isOpen={isOpen} />
      </div>
    </>
  );
};
