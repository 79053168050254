import React from "react";
import "./scss/index.scss";
import { ImCross } from "react-icons/im";
import logo from "../../assets/images/sports.png";

export const Sidebar = ({ ToggleSidebar, isOpen }) => {
  return (
    <div>
      <div className={`sidebar ${isOpen === true ? "active" : ""}`}>
        <div className="sd-header">
          {/* <h4 className="mb-0">Sidebar Header</h4> */}
          <img src={logo} alt="logo" className="w-28" />
          <div className="btn-primary p-4 text-base rounded-md cursor-pointer" onClick={ToggleSidebar}>
            {/* <i className="fa fa-times"></i> */}
            <ImCross />
          </div>
        </div>
        <div className="sd-body">
          <ul>
          <li><a href="/" className="sd-link">Home</a></li>
          <li><a href="/buy" className="sd-link">Buy</a></li>
          <li><a href="/about" className="sd-link">About</a></li>
          <li><a href="/marketplace" className="sd-link">Marketplace</a></li>
          <li><a href="/register-player" className="sd-link">Register Player</a></li>
          <li><a href="/contact-us" className="sd-link">Contact Us</a></li>
          <li><a href="/faq" className="sd-link">FAQ</a></li>

          </ul>
        </div>
      </div>
      <div
        className={`sidebar-overlay ${isOpen === true ? "active" : ""}`}
        onClick={ToggleSidebar}
      ></div>
    </div>
  );
};
