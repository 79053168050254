import React from "react";
import "./scss/index.scss";
import descimg from "../../../assets/images/aboutimages/2nd-section-image.png";
export const SecondSectionAbout = () => {
  return (
    <div className="second_sectionAbout">
      <div className="nested_secondSection flex justify-between  gap-4">
        <div className="content_section">
          <div className="subheading">
            <h3>OPENING THE DIGITAL BOX</h3>
          </div>
          <div className="main-heading">
            <h1>TOKENIZE MOMENTS</h1>
          </div>
          <div className="desc_para">
            <p>
            At YoungBlockStars, we recognize the transformative power of blockchain in sports. By tokenizing key moments in the careers of young athletes, we offer an innovative way to capture and immortalize their early achievements and milestones.
            </p>
            <p>
            These digital collectibles not only celebrate the athlete's progress but also provide a tangible connection between the athlete and their supporters.
            </p>
            <p>
            As these young talents develop and achieve greater successes, the value and significance of their tokenized moments can appreciate, creating a unique and dynamic asset for fans and collectors alike.
            </p>
          </div>
        </div>
        <div className="img_section">
          <img src={descimg} alt="descimg" />
        </div>
      </div>
    </div>
  );
};
