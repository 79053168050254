import React from 'react';
import { Header, Footer } from "../index";
import {TopQA} from "../../modules/index";
export const FaqsUs = () => {
  return (
    <div>
        <Header/>
        <div>
        {/* <h1>HEllo world</h1> */}
        <TopQA/>
        </div>
        <Footer/>
    </div>
  )
}
