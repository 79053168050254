import React from "react";
import "./scss/index.scss";
import { BsTwitter, BsInstagram, BsLinkedin } from "react-icons/bs";
import { BsDiscord } from "react-icons/bs";
import { BiDownArrow } from "react-icons/bi";

export const TopSection = () => {
  return (
    <div className="top_aboutSection">
      <div className="nested_topSection relative flex flex-col justify-center items-center">
        <p className="about_para">What we Do</p>
        <h1>About Us</h1>
        <p className="about_topDesc">
        At GSG Global, we are pioneering the intersection of blockchain technology and talent recognition. Our project, YoungBlockStars, is dedicated to spotlighting exceptional young individuals in both athletics and academics. By tokenizing the achievements and potential of these emerging stars, we create unique digital collectibles that follow them throughout their careers. Our innovative platform not only celebrates these young talents but also provides a secure and dynamic way for fans and supporters to engage with their journeys. At GSG Global, we are committed to harnessing the power of blockchain to transform how we appreciate and invest in the next generation of achievers.
        </p>
        <div className="social_icon flex justify-center items-center gap-5">
          <BsInstagram className="text-white text-2xl" />
          <BsTwitter className="text-white text-2xl" />
          <BsDiscord className="text-white text-2xl" />
          <BsLinkedin className="text-white text-2xl" />
        </div>
        <div className="absolute bottom-9 arrow_sign">
          <BiDownArrow className="text-4xl" />
        </div>
      </div>
    </div>
  );
};
