import React from "react";
import "./scss/index.scss";
import { Btn } from "../../../components";
export const GetAccessSection = () => {
  return (
    <div className="getAccess_Section">
      <div className="nested_accessSection flex flex-col justify-center items-center">
        <div>
          <h2>Connect and start minting!</h2>
        </div>
        <div className="btn_accesSection">
          <Btn value={"Get Started"} />
        </div>
      </div>
    </div>
  );
};
