import React from "react";
import {
  LandingTop,
  SectionTwo,
  UpComingAtheletes,
  UniversityAthlete,
  BrowsieMarketPlace,
  MidSchoolAtheletes,
  NFTMarketDesc,
  GetAccessSection,
  // pages
} from "../../modules/index";
import { Header, Footer } from "../index";
export const Home = () => {
  return (
    <div>
      <Header />
      <div className="">
        <LandingTop />
        {/* <h1>Hello World</h1> */}
        <SectionTwo />
        <UpComingAtheletes />
        <UniversityAthlete />
        <MidSchoolAtheletes />
        <NFTMarketDesc />
        <BrowsieMarketPlace />
        <GetAccessSection />
      </div>
      <Footer />
    </div>
  );
};
