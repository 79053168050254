import "./App.scss";
// import { Header, Footer } from "./layout";
// import {
//   LandingTop,
//   SectionTwo,
//   UpComingAtheletes,
//   UniversityAthlete,
//   BrowsieMarketPlace,
//   MidSchoolAtheletes,
//   NFTMarketDesc,
//   GetAccessSection,
// } from "./modules";
import { MainRouting } from "./layout/routing";

function App() {
  return (
    <div className="">
      <MainRouting />
    </div>
  );
}

export default App;
