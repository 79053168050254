import React from "react";
import "./scss/index.scss";
import { Btn } from "../../../components";
import { BiDownArrow } from "react-icons/bi";
export const LandingTop = () => {
  return (
    <div className="landingPage_top">
      <div className="w-full text-white flex flex-col justify-center items-center nested_landing_top">
        <p>YOUNG ATHELETES STRIVING FOR GREATNESS</p>
        <h1>BLOCK STARS</h1>
        <div className="btn_landingPage_top">
          <Btn value={"Browse the marketplace"} />
        </div>
        <div className="absolute bottom-24 arrowicon_div">
          <BiDownArrow />
        </div>
      </div>
    </div>
  );
};
